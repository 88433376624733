import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

import store from "@/store";

//delete entity by id
export const deleteFilter = async (filterId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if (!appUsingMode) {
    return;
  }
  const selectedFilter = await fetchDataCustomdashboardIdFromLocal(filterId);
  if (selectedFilter) {
    await db.filters.delete(selectedFilter);
  }
};

//update single entity
export const updateFilterData = async (filterId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if (!appUsingMode) {
    return;
  }
  if (data?.id) {
    delete data.id;
  }
  const selectedFilter = await fetchDataCustomdashboardIdFromLocal(filterId);
  if (selectedFilter) {
    await db.filters.delete(selectedFilter);
    await addSingleFilterToLocal(data);
  } else {
    await addSingleFilterToLocal(data);
  }
};

export const fetchDataCustomdashboardIdFromLocal = async (id) => {
  const [selectedEntityCustomization] = await db.filters
    .where("_id")
    .equals(id)
    .primaryKeys();
  return selectedEntityCustomization;
};

export const addSingleFilterToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if (!appUsingMode) {
    return;
  }
  return db.filters.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

export const fetchFilterById = async (id) => {
  let filters;
  filters = await fetchFilterByIdFromLocal(id);
  if (!filters) {
    filters = await fetchFilterByIdFromDatabase(id);
  }
  return filters;
};
export const fetchFilterByIdFromLocal = async (id) => {
  return await db.filters.get({ _id: id });
};
export const fetchFilterByIdFromDatabase = async (id) => {
  const response = await postAPICall("GET", `entity-filter/${id}`);
  return response.data;
};

export const fetchEntitiesAndFilters = async (params) => {
  try {
    let filters;
    filters = await fetchEntitiesAndFiltersFromLocal(params);
    if (!filters.entities) {
      filters = await fetchEntitiesAndFiltersFromDatabase(params);
    } else {
      await Promise.all(
        filters.entities.map(async (e) => {
          e.templates = await Promise.all(
            e.templates.map(async (t) => {
              t.template_id = await db.templates.get({ _id: t.template_id });
              return t;
            })
          );
          return e;
        })
      );
    }
    return filters;
  } catch (e) {
    console.log("fetchEntitiesAndFilters", e);
    return [];
  }
};

export const fetchEntitiesAndFiltersFromLocal = async (params) => {
  return {
    entities: await db.entity.where("_id").anyOf(params.entities).toArray(),
    filters: await db.filters.where("_id").anyOf(params.filters).toArray(),
  };
};

export const fetchEntitiesAndFiltersFromDatabase = async (params) => {
  return postAPICall("POST", `/filters/all-filters`, params);
};

export const fetchAllEntityFiltersByEntity = async (params) => {
  try {
    let filters;
    filters = await fetchFiltersFromLocal(params);
    if (!filters.length) {
      filters = await fetchFiltersFromDatabase(params);
    }
    return filters;
  } catch (e) {
    console.log("fetchAllEntityFiltersByEntity", e);
    return [];
  }
};

export const fetchFiltersFromLocal = async (params) => {
  return await db.filters.where("entity_id").equals(params.entityId).toArray();
};

export const fetchFiltersFromDatabase = async (params) => {
  const result = await postAPICall("GET", `/entity-filter`, params);
  return result.data
};
